import { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'
import { addForwardSlash } from '@/lib/helpers'
import Link from 'next/link'
import Icon from '@/components/icon/icon'
import ReactMarkdown from 'react-markdown'
import rehypeRaw from 'rehype-raw'
import styleConfig from '@/styles/style-config'
import { styled } from 'stitches.config'
import { ctaClickGTM } from '@/lib/gtm'
import { HeroBackgroundImage } from '@/components/image'
import { prepareCollectionData } from '@/lib/product-helper'

const GridLines = dynamic(() => import('@/components/common/grid-lines'))
const ProductList = dynamic(() => import('@/components/product/product-list'))

const HeroImage = styled(HeroBackgroundImage, {
  height: '626px',
  '@md': { height: '650px' },
  '@lg': { height: '650px' },
  '@xl': { height: '800px' },
})

const HeroTextOverlaysImage = ({ VideoBackground, ...props }) => {
  const [products, setProducts] = useState([])
  const textColor = props?.textColor ? props?.textColor : 'Black' // default to black
  const overlayType = props?.overlayType ? props?.overlayType : null
  const showBodyTextOnMobile =
    props?.showOverlayBodyTextonMobile != null ? props?.showOverlayBodyTextonMobile : true
  //GTM
  const handleCtaClick = (text, url) => {
    ctaClickGTM(text, url)
  }
  //

  const mobileBg =
    props.mobileBackgroundImage?.url ||
    props.tabletBackgroundImage?.url ||
    props.backgroundImage?.url
  const tabletBg =
    props.tabletBackgroundImage?.url ||
    props.backgroundImage?.url ||
    props.mobileBackgroundImage?.url
  const desktopBg =
    props.backgroundImage?.url ||
    props.tabletBackgroundImage?.url ||
    props.mobileBackgroundImage?.url

  useEffect(() => {
    if (props.products && props.products.length > 0) {
      prepareCollectionData(props.products).then((data) => {
        setProducts(data)
      })
    }
  }, [props.products])

  return (
    <>
      <div className={`hero hero--text-overlays-image  relative ${props?.useMediumImage && props.layoutVersion === 'Text overlays image' ? 'text-overlay-layout' : '' }`}>
        <HeroImage
          useMediumImage={props?.useMediumImage}
          imageProps={{
            priority: true,
            quality: 85,
            objectFit: 'cover',
            srcMap: {
              mobile: mobileBg,
              tablet: tabletBg,
              desktop: desktopBg,
            },
            height: props?.useMediumImage ? 600 : 1620,
            width: 800
          }}
        >
          {overlayType && (
            <div
              className={`overlay absolute inset-0 ${
                overlayType == 'Light' ? 'overlay--light' : 'overlay--dark'
              }`}
            ></div>
          )}
          {props?.useGridLines && <GridLines gridLinesColor={props?.gridLinesColor} />}
          <div className="hero--text-overlays-image__text-wrap text-white relative h-full flex items-end w-full lg:w-8/12 px-16 md:px-0">
            <div className="w-full text-center lg:text-left md:px-20">
              <h1
                className={`${
                  props?.useSecondaryFontFamily
                    ? 'font-secondary font-semibold font-60 font-70 headline-l-height'
                    : 'font-primary font-normal font-65 xl:text-6xl headline-line-height'
                } font-40 font-50 tracking-tight break-words sm:break-normal text-center lg:text-left uppercase ${
                  textColor == 'Black' ? 'text-black' : 'text-white'
                }`}
                // {/* dangerouslySetInnerHTML={{ __html: props?.headline }} */}
              >
                <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props?.headline}</ReactMarkdown>
              </h1>
              {props?.bodyText && (
                <div
                  className={`font-primary font-normal mt-5 text-base md:text-smd font-21 font-25 leading-5 md:leading-26 lg:leading-7 xl:leading-8 lg:tracking-tight ${
                    textColor == 'Black' ? 'text-black' : 'text-white'
                  } w-full lg:w-9/12 ${!showBodyTextOnMobile ? 'hidden md:block' : ''}`}
                >
                  <ReactMarkdown rehypePlugins={[rehypeRaw]}>{props?.bodyText}</ReactMarkdown>
                </div>
              )}
              <div className="mt-7 button-group-wrap flex justify-center gap-y-5 gap-x-10 lg:justify-start flex-wrap">
                {props?.ctaLeftUrl && props?.ctaLeftText && (
                  <Link
                    href={addForwardSlash(props?.ctaLeftUrl)}
                    onClick={() => {
                      handleCtaClick(props?.ctaLeftText, props?.ctaLeftUrl)
                    }}
                    className={`btn flex items-center rounded-full relative px-5 py-18 text-smd lg:whitespace-no-wrap leading-18 font-medium  active:bg-gray-700 ${
                      textColor == 'Black'
                        ? 'text-white bg-black hover:bg-gray-800'
                        : 'text-black bg-white'
                    }`}
                  >
                    <span className={`${props?.ctaLeftIcon ? 'pr-4' : ''}`}>
                      {props?.ctaLeftText}
                    </span>
                    {props?.ctaLeftIcon && (
                      <Icon
                        className={`cursor-pointer text-newOrange fill-current text-2xl flex-shrink-0`}
                        viewBox={`0 0 12 12`}
                        size={12}
                        fill={'#E36F22'}
                        icon="arrow"
                      />
                    )}
                  </Link>
                )}
                {props?.ctaRightUrl && props?.ctaRightText && (
                  <Link
                    href={addForwardSlash(props?.ctaRightUrl)}
                    onClick={() => {
                      handleCtaClick(props?.ctaRightText, props?.ctaRightUrl)
                    }}
                    className={`btn flex items-center rounded-full relative px-5 py-18 text-smd lg:whitespace-no-wrap leading-18 font-medium active:bg-gray-700 ${
                      textColor == 'Black'
                        ? 'text-white bg-black hover:bg-gray-800'
                        : 'text-black bg-white'
                    }`}
                  >
                    <span className={`${props?.ctaRightIcon ? 'pr-4' : ''}`}>
                      {props?.ctaRightText}
                    </span>

                    {props?.ctaRightIcon && (
                      <Icon
                        className={`cursor-pointer text-newOrange fill-current text-2xl flex-shrink-0`}
                        viewBox={`0 0 12 12`}
                        size={12}
                        fill={'#E36F22'}
                        icon="arrow"
                      />
                    )}
                  </Link>
                )}
              </div>
            </div>
          </div>
          {/* Show white background when autoplay to prevent bg image flash */}
          {props.useVideoBackground && props.autoplay && (
            <div className="absolute inset-0 bg-white"></div>
          )}
          {props?.useVideoBackground && (
            <VideoBackground video={props?.video} autoplay={props.autoplay} />
          )}
        </HeroImage>
        {products?.length > 0 && (
          <div className="relative z-10">
            <div
              className="absolute right-65 bottom-55 bottom-30 right-20 p-1 rounded-full circle-section cursor-pointer"
              onTouchStart={() => {
                props?.showProductList()
              }}
              onMouseEnter={() => {
                props?.showProductList()
              }}
            >
              <div
                className={`${
                  props?.showProductMenu !== null
                    ? props?.showProductMenu
                      ? ''
                      : 'plus-icon-on-show'
                    : 'plus-icon-on-reload'
                }`}
              >
                <Icon
                  className={`cursor-pointer text-white fill-current w-7 h-7`}
                  viewBox={`0 0 36 36`}
                  size={36}
                  icon="plus-circle"
                />
              </div>
            </div>
            {!props?.isStory && (
              <ProductList
                showProductMenu={props?.showProductMenu}
                setShowProductMenu={props?.setShowProductMenu}
                products={products}
              />
            )}
          </div>
        )}
      </div>
      <style jsx>{`
        .hero--text-overlays-image__text-wrap {
          padding-bottom: 28px;
        }

        .overlay--dark {
          background: linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
        }

        .overlay--light {
          background: linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5));
        }

        @media (min-width: ${styleConfig.theme.screens.xl}) {
          .hero--text-overlays-image__text-wrap {
            padding-bottom: 55px;
          }
        }
      `}</style>
    </>
  )
}

export default HeroTextOverlaysImage
